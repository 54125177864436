@import "https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css";
@import "https://fonts.googleapis.com/css?family=Poppins:300,700,500,600";
@import "https://px.animaapp.com/62e26965072e5caa0aa1b231.62e269667439e7a06820b837.5Wp6Eu0.rcp.png";
.screen a {
  text-decoration: none;
}

.screen a:not(.button-link) {
  display: contents;
}

.container-center-horizontal {
  pointer-events: none;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.container-center-horizontal > * {
  pointer-events: auto;
  flex-shrink: 0;
}

* {
  box-sizing: border-box;
}

:root {
  --black: #000;
  --white: #fff;
  --font-size-l: 20px;
  --font-size-m: 16px;
  --font-size-s: 14px;
  --font-size-xl: 22px;
  --font-size-xs: 13px;
  --font-size-xxl: 32px;
  --font-family-poppins: "Poppins", Helvetica;
}

.poppins-semi-bold-black-32px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 600;
}

.poppins-medium-black-20px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.poppins-bold-black-22px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}



.apts-short-version {
  background-color: var(--white);
  height: 1162px;
  width: 1440px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.flex-col {
  min-height: 688px;
  width: 1440px;
  flex-direction: column;
  align-items: center;
  margin-top: 26px;
  display: flex;
}

.flex-row {
  min-width: 1240px;
  align-items: center;
  display: flex;
}

.logo {
  height: 94px;
  object-fit: cover;
  width: 232px;
}

.contact-icons {
  height: min-content;
  width: min-content;
  align-items: flex-start;
  gap: 30px;
  margin-left: 807px;
  display: flex;
}

.icon {
  height: 28px;
  min-width: 28px;
}

.icon-linkedin {
  height: 26.54px;
  min-width: 28px;
}

.icon-call {
  height: 27px;
  min-width: 27px;
}

.overlap-group {
  min-height: 451px;
  width: 1440px;
  background-position: 50%;
  background-size: cover;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
  padding: 109px 170px;
  display: flex;
}

.software-services-an {
  letter-spacing: 0;
  min-height: 96px;
  width: 409px;
}

.get-in-touch-button {
  height: min-content;
  width: 191px;
  background-color: #ee6a3e;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 60px;
  margin-left: 10px;
  padding: 11px 55px;
  display: flex;
  box-shadow: 2px 2px 4px #00000040;
}

.get-in-touch {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  min-width: 89px;
  margin-top: -1px;
  margin-left: -4px;
  margin-right: -4px;
  font-weight: 500;
}

.flex-row-1 {
  height: 33px;
  min-width: 786px;
  align-items: flex-start;
  margin-top: 80px;
  margin-right: 314px;
  display: flex;
}

.about {
  letter-spacing: 0;
  min-height: 33px;
  min-width: 77px;
}

.services {
  letter-spacing: 0;
  min-height: 33px;
  margin-left: 604px;
}

.flex-row-2 {
  height: 123px;
  min-width: 1046px;
  align-items: flex-start;
  margin-top: 69px;
  margin-right: 54px;
  display: flex;
}

.lorem-ipsum-dolor-si {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  letter-spacing: 0;
  min-height: 117px;
  width: 512px;
  margin-top: 1px;
  font-weight: 300;
}

.flex-col-1 {
  min-height: 123px;
  width: 348px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 186px;
  display: flex;
}

.it-consult-container {
  min-width: 233px;
  align-items: flex-end;
  margin-left: 2px;
  display: flex;
}

.x-icon {
  height: 45px;
  width: 45px;
}

.it-consultancy {
  letter-spacing: 0;
  min-height: 30px;
  min-width: 150px;
  margin-bottom: 3px;
  margin-left: 38px;
}

.infrastructure-engineering-container {
  min-width: 348px;
  align-items: center;
  margin-top: 33px;
  display: flex;
}

.infrastructure-engineering {
  letter-spacing: 0;
  min-height: 30px;
  min-width: 263px;
  margin-bottom: 3px;
  margin-left: 40px;
}

.flex-col-2 {
  min-height: 228px;
  width: 1440px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 28px;
  display: flex;
}

.software-engineering-container {
  min-width: 299px;
  align-self: flex-end;
  align-items: center;
  margin-right: 273px;
  display: flex;
}

.software-engineering {
  letter-spacing: 0;
  min-height: 30px;
  min-width: 214px;
  margin-bottom: 1px;
  margin-left: 40px;
}

.technologies-icons {
  height: 26px;
  width: 493px;
  margin-top: 8px;
  margin-left: 170px;
}

.footer {
  height: 50px;
  min-width: 1440px;
  background-color: #6a6a6a1a;
  align-items: flex-start;
  margin-top: 99px;
  padding: 14px 253px;
  display: flex;
}

.x2022-alkis-petrou {
  color: #0000008c;
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xs);
  letter-spacing: 3.25px;
  min-height: 20px;
  font-weight: 300;
}

/*# sourceMappingURL=index.85deb08b.css.map */
