:root { 
  --black: #000000;
  --white: #ffffff;
 
  --font-size-l: 20px;
  --font-size-m: 16px;
  --font-size-s: 14px;
  --font-size-xl: 22px;
  --font-size-xs: 13px;
  --font-size-xxl: 32px;
 
  --font-family-poppins: "Poppins", Helvetica;
}
.poppins-semi-bold-black-32px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 600;
}

.poppins-medium-black-20px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.poppins-bold-black-22px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 700;
}
