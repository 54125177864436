.apts-short-version {
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  height: 1162px;
  width: 1440px;
}

.flex-col {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 26px;
  min-height: 688px;
  width: 1440px;
}

.flex-row {
  align-items: center;
  display: flex;
  min-width: 1240px;
}

.logo {
  height: 94px;
  object-fit: cover;
  width: 232px;
}

.contact-icons {
  align-items: flex-start;
  display: flex;
  gap: 30px;
  height: min-content;
  margin-left: 807px;
  width: min-content;
}

.icon {
  height: 28px;
  min-width: 28px;
}

.icon-linkedin {
  height: 26.54px;
  min-width: 28px;
}

.icon-call {
  height: 27px;
  min-width: 27px;
}

.overlap-group {
  align-items: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  min-height: 451px;
  padding: 109px 170px;
  width: 1440px;
}

.software-services-an {
  letter-spacing: 0;
  min-height: 96px;
  width: 409px;
}

.get-in-touch-button {
  align-items: center;
  background-color: #ee6a3e;
  border-radius: 4px;
  box-shadow: 2px 2px 4px #00000040;
  display: flex;
  gap: 10px;
  height: min-content;
  justify-content: center;
  margin-left: 10px;
  margin-top: 60px;
  padding: 11px 55px;
  width: 191px;
}

.get-in-touch {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-weight: 500;
  letter-spacing: 0;
  margin-left: -4px;
  margin-right: -4px;
  margin-top: -1px;
  min-width: 89px;
}

.flex-row-1 {
  align-items: flex-start;
  display: flex;
  height: 33px;
  margin-right: 314.0px;
  margin-top: 80px;
  min-width: 786px;
}

.about {
  letter-spacing: 0;
  min-height: 33px;
  min-width: 77px;
}

.services {
  letter-spacing: 0;
  margin-left: 604px;
  min-height: 33px;
}

.flex-row-2 {
  align-items: flex-start;
  display: flex;
  height: 123px;
  margin-right: 54.0px;
  margin-top: 69px;
  min-width: 1046px;
}

.lorem-ipsum-dolor-si {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-weight: 300;
  letter-spacing: 0;
  margin-top: 1px;
  min-height: 117px;
  width: 512px;
}

.flex-col-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 186px;
  min-height: 123px;
  width: 348px;
}

.it-consult-container {
  align-items: flex-end;
  display: flex;
  margin-left: 2px;
  min-width: 233px;
}

.x-icon {
  height: 45px;
  width: 45px;
}

.it-consultancy {
  letter-spacing: 0;
  margin-bottom: 3px;
  margin-left: 38px;
  min-height: 30px;
  min-width: 150px;
}

.infrastructure-engineering-container {
  align-items: center;
  display: flex;
  margin-top: 33px;
  min-width: 348px;
}

.infrastructure-engineering {
  letter-spacing: 0;
  margin-bottom: 3.0px;
  margin-left: 40px;
  min-height: 30px;
  min-width: 263px;
}

.flex-col-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  min-height: 228px;
  width: 1440px;
}

.software-engineering-container {
  align-items: center;
  align-self: flex-end;
  display: flex;
  margin-right: 273px;
  min-width: 299px;
}

.software-engineering {
  letter-spacing: 0;
  margin-bottom: 1.0px;
  margin-left: 40px;
  min-height: 30px;
  min-width: 214px;
}

.technologies-icons {
  height: 26px;
  margin-left: 170px;
  margin-top: 8px;
  width: 493px;
}

.footer {
  align-items: flex-start;
  background-color: #6a6a6a1a;
  display: flex;
  height: 50px;
  margin-top: 99px;
  min-width: 1440px;
  padding: 14px 253px;
}

.x2022-alkis-petrou {
  color: #0000008c;
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xs);
  font-weight: 300;
  letter-spacing: 3.25px;
  min-height: 20px;
}

